.paymentForm{
    top: 50%;
    left: 50%;
    width: 80%;
    /* max-height: 400px; */
    transform: translate(-50%, -50%);
    border: 1px solid #666;
    border-radius: 34px;
    background-color: #FFFFFF;
    position: fixed;
    display: flex;
    flex-direction: column;
    overflow: auto;
    
}

.paymentForm .header{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    color: #151515;
    margin-bottom: 0;
    text-align: center;
}

.paymentForm .subheader{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #616163;
    margin-bottom: 0;
    text-align: center;
    width: 75%;
    margin: auto;
}
.paymentForm .ul-listArea{
    list-style-type: none;
    padding-left: 13px;
    padding-right: 13px;
}

.paymentForm .ul-listArea li{
    /* height: 40px; */
    background: #FFFFFF;
    border: 1px solid #616163;
    border-radius: 12px;
    margin-bottom: 16px;
    text-align: left;
    vertical-align: middle;
    padding: 2%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #616163; 
}

.paymentForm .ul-listArea li input[type='radio'] { 
    transform: scale(1.5);
    margin-right: 10px;
    accent-color: #000064;
}

.paymentForm .ul-listArea li input[type='radio']:checked ~ label {
    color: #000064;
  }

 .paymentForm .ul-listArea li label {
    /* display: flex;
    align-items: center; */
    width: 100%;
  } 


  /* .paymentForm button{
    box-sizing: border-box;

    width: 80%;
    height: 48px;
    
    
    background: #FFAF00;
    border: 1px solid #FFAF00;
    border-radius: 100px;
    text-align: center;
    margin: auto;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #FFFFFF;
    margin-bottom: 51px;
} */

@media screen and (orientation:landscape)
 {
   /* Your CSS Here*/
   .paymentForm{
    
    max-height: 80vh; 
    overflow: auto;
   }

   .paymentForm .ul-listArea li{
    padding-top: 3%;
    
}
}