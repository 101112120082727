.confirmPaymentForm{
    top: 50%;
    left: 50%;
    width: 80%;
    /* max-height: 400px; */
    transform: translate(-50%, -50%);
    border: 1px solid #666;
    border-radius: 34px;
    background-color: #FFFFFF;
    position: fixed;
    display: flex;
    flex-direction: column;
    overflow: auto;
    
}

.confirmPaymentForm .header{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #151515;
    margin-bottom: 41px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

}

.confirmPaymentForm .subheader{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #616163;
    margin-bottom: 0;
    text-align: center;
    width: 75%;
    margin: auto;
    margin-bottom: 94px;
}


@media screen and (orientation:landscape)
 {
   /* Your CSS Here*/
   .confirmpaymentForm{
    
    max-height: 80vh; 
    overflow: auto;
   }

}